import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Realm from "realm-web";

function Tasks({setLoggedIn}) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [taskData, setTaskData] = useState({
        name: '',
        hourlyRate: '',
        dailyRate: '',
    });
    const [tasks, setTasks] = useState([]);
    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');
    const app = new Realm.App({ id: "data-ywwpoom" });
    const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

    useEffect(() => {
      if (!token) {
        navigate('/login');
        return;
      }
  
      validateToken(token)
        .then(async isValid => {
          if (!isValid) {
            if (stayLoggedIn) {
            const refreshed = await refreshAccessToken();
            if (!refreshed) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              const newToken = app.currentUser.accessToken;
              const isValidNewToken = await validateToken(newToken);
              if (!isValidNewToken) {
                setLoggedIn(false);
                navigate('/login');
              } else {
                setLoggedIn(true);
              }
            }
          } else {
            setLoggedIn(false);
            navigate('/login');
          }
        } else {
            setLoggedIn(true);
          }
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setLoggedIn(false);
          navigate('/login');
        });
    }, [token, setLoggedIn, navigate, stayLoggedIn]);
    
      const validateToken = (token) => {
        return new Promise((resolve, reject) => {
          try {
            let requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': '*',
              },
              body: JSON.stringify({ token: token }),
              redirect: 'follow'
            };
    
            fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
              .then(response => response.json())
              .then(result => {
                if (result.error) {
                  resolve(false);
                } else {
                  console.log(result);
                  resolve(true);
                }
              })
              .catch(error => {
                console.error('Error:', error);
                reject(error);
              });
          } catch (error) {
            console.error('Error:', error);
            reject(error);
          }
        });
      };
    
      const refreshAccessToken = async () => {
        try {
          await app.currentUser.refreshAccessToken();
          const newAccessToken = app.currentUser.accessToken;
          localStorage.setItem('accessToken', newAccessToken);
          return true;
        } catch (error) {
          console.error('Error refreshing token:', error);
          return false;
        }
      };

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getTasks', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token
                }),
            });

            const result = await response.json();
            console.log(result)

            if (result.error) {
                setError(result.error);
            } else {
                setTasks(result);
            }
        } catch (error) {
            setError("An error occurred while fetching the tasks");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaskData({
            ...taskData,
            [name]: value
        });
    };

    const handleAddTask = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    taskData
                }),
            });

            const result = await response.json();

            if (result.error) {
                setError(result.error);
            } else {
                setIsDialogOpen(false);
                fetchTasks();
            }
        } catch (error) {
            setError("An error occurred while adding the task");
        }
    };

    const handleEditTask = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    taskId: currentTaskId,
                    taskData
                }),
            });

            const result = await response.json();

            if (result.error) {
                setError(result.error);
            } else {
                setIsEditDialogOpen(false);
                fetchTasks();
            }
        } catch (error) {
            setError("An error occurred while editing the task");
        }
    };

    const handleDeleteTask = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/deleteTask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    taskId: currentTaskId
                }),
            });

            const result = await response.json();

            if (result.error) {
                setError(result.error);
            } else {
                setIsEditDialogOpen(false);
                fetchTasks();
            }
        } catch (error) {
            setError("An error occurred while deleting the task");
        }
    };

    const openEditDialog = (task) => {
        setTaskData({
            name: task.name,
            hourlyRate: task.hourlyRate,
            dailyRate: task.dailyRate,
        });
        setCurrentTaskId(task._id);
        setIsEditDialogOpen(true);
    };

    return (
        <div className="px-[90px] py-[112px]">
            <div className="flex justify-between items-center mb-[72px]">
                <h1 className="text-[60px] leading-[63px] font-bold">Tätigkeitenliste</h1>
                <button
                    className="bg-[#0000FF] text-white px-[20px] py-[12px] rounded-lg"
                    onClick={() => setIsDialogOpen(true)}
                >
                    Hinzufügen
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead>
                        <tr>
                            <th className="pt-[64px] py-3 px-4 border-b text-left">Name</th>
                            <th className="pt-[64px] py-3 px-4 border-b text-left">Stundensatz</th>
                            <th className="pt-[64px] py-3 px-4 border-b text-left">Tagessatz</th>
                            <th className="pt-[64px] py-3 px-4 border-b text-left"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map((task) => (
                            <tr key={task._id}>
                                <td className="pb-[64px] py-3 px-4 border-b">{task.name}</td>
                                <td className="pb-[64px] py-3 px-4 border-b">{task.hourlyRate}</td>
                                <td className="pb-[64px] py-3 px-4 border-b">{task.dailyRate}</td>
                                <td className="pb-[64px] py-3 px-8 border-b text-[#0000FF] text-right font-[700]">
                                    <button onClick={() => openEditDialog(task)}>Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isDialogOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg">
                        <h2 className="text-xl font-bold mb-4">Tätigkeit bearbeiten</h2>
                        <div className="mb-4">
                            <label className="block mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={taskData.name}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Stundensatz</label>
                            <input
                                type="text"
                                name="hourlyRate"
                                value={taskData.hourlyRate}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Tagessatz</label>
                            <input
                                type="text"
                                name="dailyRate"
                                value={taskData.dailyRate}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="flex justify-between pt-[25px]">
                            <button
                                onClick={() => setIsDialogOpen(false)}
                                className=" text-[#0000FF] py-2 px-4 rounded-md"
                            >
                                Abbrechen
                            </button>
                            <button
                                onClick={handleAddTask}
                                className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                            >
                                Speichern
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isEditDialogOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg">
                        <h2 className="text-xl font-bold mb-4">Tätigkeit bearbeiten</h2>
                        <div className="mb-4">
                            <label className="block mb-2">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={taskData.name}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Stundensatz</label>
                            <input
                                type="text"
                                name="hourlyRate"
                                value={taskData.hourlyRate}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2">Tagessatz</label>
                            <input
                                type="text"
                                name="dailyRate"
                                value={taskData.dailyRate}
                                onChange={handleInputChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div className="flex justify-between pt-[25px]">
                            <button
                                onClick={handleDeleteTask}
                                className="bg-red-600 text-white py-2 px-4 rounded-md"
                            >
                                Tätigkeit löschen
                            </button>
                            <div className="flex">
                                <button
                                    onClick={() => setIsEditDialogOpen(false)}
                                    className=" text-[#0000FF] py-2 px-4 rounded-md mr-2"
                                >
                                    Abbrechen
                                </button>
                                <button
                                    onClick={handleEditTask}
                                    className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                                >
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="absolute bottom-0 left-0 p-6">
                <img src="../images/task-monster.png" alt="Task Monster" />
            </div> */}
        </div>
    );
}

export default Tasks;
