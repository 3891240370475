import React, { useState, useEffect } from 'react';

const EditTimeDialog = ({ isOpen, onClose, onSave, initialData }) => {
  const [editData, setEditData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    pause: ''
  });

  useEffect(() => {
    if (initialData) {
      setEditData(initialData);
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };

  const handleSave = () => {
    onSave(editData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[500px] p-6">
        <h2 className="text-xl font-bold mb-4">Zeit bearbeiten</h2>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Datum</label>
          <input 
            type="date" 
            name="date"
            value={editData.date}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Startzeit</label>
          <input 
            type="time" 
            name="startTime"
            value={editData.startTime}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Endzeit</label>
          <input 
            type="time" 
            name="endTime"
            value={editData.endTime}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Pause</label>
          <input 
            type="time" 
            name="pause"
            value={editData.pause}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        
        <div className="flex justify-end">
          <button 
            className="bg-[#0000FF] text-white py-2 px-4 rounded-lg mr-2"
            onClick={handleSave}
          >
            Speichern
          </button>
          <button 
            className="text-[#0000FF] py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTimeDialog;