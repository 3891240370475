import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Realm from "realm-web";

function Projects({ setLoggedIn }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [projectData, setProjectData] = useState({
    name: '',
    hourlyRate: '',
    dailyRate: '',
    budget: '',
    note: '',
    clientId: '',
  });
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  const app = new Realm.App({ id: "data-ywwpoom" });
  const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    validateToken(token)
      .then(async isValid => {
        if (!isValid) {
          if (stayLoggedIn) {
          const refreshed = await refreshAccessToken();
          if (!refreshed) {
            setLoggedIn(false);
            navigate('/login');
          } else {
            const newToken = app.currentUser.accessToken;
            const isValidNewToken = await validateToken(newToken);
            if (!isValidNewToken) {
              setLoggedIn(false);
              navigate('/login');
            } else {
              setLoggedIn(true);
            }
          }
        } else {
          setLoggedIn(false);
          navigate('/login');
        }
      } else {
          setLoggedIn(true);
        }
      })
      .catch(error => {
        console.error('Error validating token:', error);
        setLoggedIn(false);
        navigate('/login');
      });
  }, [token, setLoggedIn, navigate, stayLoggedIn]);

  const validateToken = (token) => {
    return new Promise((resolve, reject) => {
      try {
        let requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Headers': '*',
          },
          body: JSON.stringify({ token: token }),
          redirect: 'follow'
        };

        fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.error) {
              resolve(false);
            } else {
              console.log(result);
              resolve(true);
            }
          })
          .catch(error => {
            console.error('Error:', error);
            reject(error);
          });
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };

  const refreshAccessToken = async () => {
    try {
      await app.currentUser.refreshAccessToken();
      const newAccessToken = app.currentUser.accessToken;
      localStorage.setItem('accessToken', newAccessToken);
      return true;
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  };

  const fetchProjects = async () => {
    if (!token) {
      setError("Authorization token not provided");
      return;
    }

    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getProjects', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const result = await response.json();

      console.log(result)

      if (result.error) {
        setError(result.error);
      } else {
        setProjects(result);
      }
    } catch (error) {
      setError("An error occurred while fetching projects");
    }
  };

  const fetchClients = async () => {
    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getClients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const result = await response.json();

      if (result.error) {
        setError(result.error);
      } else {
        setClients(result);
      }
    } catch (error) {
      setError("An error occurred while fetching clients");
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchClients();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData({
      ...projectData,
      [name]: value
    });
  };

  const handleAddProject = async () => {
    if (!token) {
      setError("Authorization token not provided");
      return;
    }

    try {
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addProject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          projectData: {
            name: projectData.name,
            hourlyRate: parseInt(projectData.hourlyRate),
            dailyRate: parseInt(projectData.dailyRate),
            budget: parseInt(projectData.budget),
            note: projectData.note,
            customer: projectData.clientId,
          }
        }),
      });

      const result = await response.json();

      console.log(result)

      if (result.error) {
        setError(result.error);
      } else {
        setIsDialogOpen(false);
        fetchProjects();
      }
    } catch (error) {
      setError("An error occurred while adding the project");
    }
  };

  const handleEditProject = (project) => {
    setIsEditing(true);
    setCurrentProjectId(project.project_id);
    setProjectData({
      name: project.name,
      hourlyRate: project['hourly-rate'],
      dailyRate: project['daily-rate'],
      budget: project.budget,
      note: project.note,
      clientId: project.clientId || '',
    });
    setIsDialogOpen(true);
  };

  const handleUpdateProject = async () => {
    console.log('Hit edit')
    if (!token) {
      setError("Authorization token not provided");
      return;
    }

    try {
        console.log(projectData.clientId)
      const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeProject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          projectId: currentProjectId,
          projectData: {
            name: projectData.name,
            "hourly-rate": parseInt(projectData.hourlyRate),
            "daily-rate": parseInt(projectData.dailyRate),
            budget: parseInt(projectData.budget),
            note: projectData.note,
            customer: projectData.clientId,
          }
        }),
      });

      const result = await response.json();

      console.log(result)

      if (result.error) {
        setError(result.error);
      } else {
        setIsDialogOpen(false);
        fetchProjects();
      }
    } catch (error) {
      setError("An error occurred while updating the project");
    }
  };

  return (
    <div className="px-[90px] py-[112px]">
      <div className="flex justify-between items-center mb-[72px]">
        <h1 className="text-[60px] leading-[63px] font-bold">Aktuelle Projekte</h1>
        <button
          className="bg-[#0000FF] text-white px-[20px] py-[12px] rounded-lg"
          onClick={() => {
            setIsEditing(false);
            setIsDialogOpen(true);
            setProjectData({
              name: '',
              hourlyRate: '',
              dailyRate: '',
              budget: '',
              note: '',
              clientId: '',
            });
          }}
        >
          Hinzufügen
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Projekt Name</th>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Kunde</th>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Stundensatz</th>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Tagessatz</th>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Budget</th>
              <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]"></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project._id}>
                <td className="pb-[64px] py-3 px-4 border-b font-bold">{project.name}</td>
                <td className="pb-[64px] py-3 px-4 border-b">
                    {clients.find(client => client._id === project.customer)?.company.name || ''}
                </td>
                <td className="pb-[64px] py-3 px-4 border-b">{project['hourly-rate']}</td>
                <td className="pb-[64px] py-3 px-4 border-b">{project['daily-rate']}</td>
                <td className="pb-[64px] py-3 px-4 border-b">{project.budget}</td>
                <td className="pb-[64px] py-3 px-8 border-b text-[#0000FF] text-right font-[700]">
                  <button onClick={() => handleEditProject(project)}>Edit</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg">
            <h2 className="text-xl font-bold mb-4">
              {isEditing ? "Projekt bearbeiten" : "Neues Projekt hinzufügen"}
            </h2>
            {error && <p className="text-red-600 mb-4">{error}</p>}
            <div className="mb-4">
              <label className="block mb-2">Projektname</label>
              <input
                type="text"
                name="name"
                value={projectData.name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
            <label className="block mb-2">Kunde</label>
                <select
                    name="clientId"
                    value={projectData.clientId}
                    onChange={handleInputChange}
                    className="w-full p-2 h-[42px] border border-gray-300 rounded-md"
                >
                    <option value=""></option>
                    {
                        clients.map((client) => (
                            <option key={client._id} value={client._id}>{client.company.name}</option>
                        ))
                    }
                    </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Stundensatz</label>
              <input
                type="number"
                name="hourlyRate"
                value={projectData.hourlyRate}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Tagessatz</label>
              <input
                type="number"
                name="dailyRate"
                value={projectData.dailyRate}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Budget</label>
              <input
                type="number"
                name="budget"
                value={projectData.budget}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Notiz</label>
              <textarea
                name="note"
                value={projectData.note}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setIsDialogOpen(false)}
                className="text-[#0000FF] py-2 px-4 rounded-md mr-2"
              >
                Abbrechen
              </button>
              <button
                onClick={isEditing ? handleUpdateProject : handleAddProject}
                className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
              >
                {isEditing ? "Aktualisieren" : "Hinzufügen"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;