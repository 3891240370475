import React, { useState } from 'react';

const AbsenceDialog = ({ isOpen, onClose, onSave }) => {
  const [absenceData, setAbsenceData] = useState({
    startTime: '',
    endTime: '',
    isFullDay: false,
    reason: '',
  });

  const isSaveDisabled = !absenceData.startTime || !absenceData.endTime;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAbsenceData({
      ...absenceData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setAbsenceData({
      ...absenceData,
      isFullDay: checked,
    });
  };

  const handleSave = () => {
    onSave(absenceData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg w-[500px] p-6">
        <h2 className="text-xl font-bold mb-4">Abwesenheit hinzufügen</h2>
        <p className="text-sm text-gray-600 mb-4">Eine angelegte Abwesenheit bedeutet, dass dem Mitarbeiter für diesen Zeitraum Arbeitsstunden angerechnet werden.</p>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Startzeit</label>
          <input 
            type="time" 
            name="startTime"
            value={absenceData.startTime}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            disabled={absenceData.isFullDay}
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Endzeit</label>
          <input 
            type="time" 
            name="endTime"
            value={absenceData.endTime}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            disabled={absenceData.isFullDay}
          />
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Grund für Abwesenheit angeben</label>
          <select 
            name="reason" 
            value={absenceData.reason}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="" disabled>Wählen Sie einen Grund</option>
            <option value="Krank">Krank</option>
            <option value="Kind Krank">Kind Krank</option>
            <option value="Angeordet">Angeordet</option>
            <option value="Urlaub">Urlaub</option>
            <option value="Zeitausgleich">Zeitausgleich</option>
            <option value="Feiertag">Feiertag</option>
          </select>
        </div>
        
        <div className="flex justify-end">
          <button 
            className={`bg-[#0000FF] text-white py-2 px-4 rounded-lg mr-2 ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleSave}
            disabled={isSaveDisabled}
          >
            Hinzufügen
          </button>
          <button 
            className="text-[#0000FF] py-2 px-4 rounded-lg"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbsenceDialog;
