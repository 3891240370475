import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Realm from "realm-web";

function Clients({ setLoggedIn }) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [clientData, setClientData] = useState({
        name: '',
        taxNumber: '',
        vatRegistrationId: '',
        allowTaxFreeInvoices: true,
        contactPersons: [{
            salutation: '',
            firstName: '',
            lastName: '',
            primary: true,
            emailAddress: '',
            phoneNumber: ''
        }],
        addresses: {
            billing: [{
                supplement: '',
                street: '',
                zip: '',
                city: '',
                countryCode: 'DE'
            }],
            shipping: [{
                supplement: '',
                street: '',
                zip: '',
                city: '',
                countryCode: 'DE'
            }]
        },
        emailAddresses: {
            business: [''],
            office: [''],
            private: [''],
            other: ['']
        },
        phoneNumbers: {
            business: [''],
            office: [''],
            mobile: [''],
            private: [''],
            fax: [''],
            other: ['']
        },
        note: ''
    });
    const [currentClientId, setCurrentClientId] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const app = new Realm.App({ id: "data-ywwpoom" });
    const stayLoggedIn = localStorage.getItem('stayLoggedIn') === 'true';

    useEffect(() => {
        if (!token) {
          navigate('/login');
          return;
        }
    
        validateToken(token)
          .then(async isValid => {
            if (!isValid) {
              if (stayLoggedIn) {
              const refreshed = await refreshAccessToken();
              if (!refreshed) {
                setLoggedIn(false);
                navigate('/login');
              } else {
                const newToken = app.currentUser.accessToken;
                const isValidNewToken = await validateToken(newToken);
                if (!isValidNewToken) {
                  setLoggedIn(false);
                  navigate('/login');
                } else {
                  setLoggedIn(true);
                }
              }
            } else {
              setLoggedIn(false);
              navigate('/login');
            }
          } else {
              setLoggedIn(true);
            }
          })
          .catch(error => {
            console.error('Error validating token:', error);
            setLoggedIn(false);
            navigate('/login');
          });
      }, [token, setLoggedIn, navigate, stayLoggedIn]);

    const validateToken = (token) => {
        return new Promise((resolve, reject) => {
        try {
            let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': '*',
            },
            body: JSON.stringify({ token: token }),
            redirect: 'follow'
            };

            fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                resolve(false);
                } else {
                console.log(result);
                resolve(true);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                reject(error);
            });
        } catch (error) {
            console.error('Error:', error);
            reject(error);
        }
        });
    };

    const refreshAccessToken = async () => {
        try {
        await app.currentUser.refreshAccessToken();
        const newAccessToken = app.currentUser.accessToken;
        localStorage.setItem('accessToken', newAccessToken);
        return true;
        } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getClients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token
                }),
            });

            const result = await response.json();
            console.log(result)

            if (result.error) {
                setError(result.error);
            } else {
                setCustomers(result);
            }
        } catch (error) {
            setError("An error occurred while fetching the clients");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientData({
            ...clientData,
            [name]: value
        });
    };

    const handleContactPersonChange = (e, index) => {
        const { name, value } = e.target;
        const updatedContactPersons = [...clientData.contactPersons];
        updatedContactPersons[index][name] = value;
        setClientData({ ...clientData, contactPersons: updatedContactPersons });
    };

    const handleAddressChange = (e, type, index) => {
        const { name, value } = e.target;
        const updatedAddresses = { ...clientData.addresses };
        updatedAddresses[type][index][name] = value;
        setClientData({ ...clientData, addresses: updatedAddresses });
    };

    const handleEmailChange = (e, type, index) => {
        const { value } = e.target;
        const updatedEmails = { ...clientData.emailAddresses };
        updatedEmails[type][index] = value;
        setClientData({ ...clientData, emailAddresses: updatedEmails });
    };

    const handlePhoneChange = (e, type, index) => {
        const { value } = e.target;
        const updatedPhones = { ...clientData.phoneNumbers };
        updatedPhones[type][index] = value;
        setClientData({ ...clientData, phoneNumbers: updatedPhones });
    };

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleAddClient = async () => {
        if (!token) {
            setError("Authorization token not provided");
            return;
        }

        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addClient', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    clientData
                }),
            });

            const result = await response.json();

            if (result.error) {
                setError(result.error);
            } else {
                setIsDialogOpen(false);
                fetchClients();
            }
        } catch (error) {
            setError("An error occurred while adding the client");
        }
    };

    const handleEditClient = async () => {
        if (!token) {
            setError("Authorization token not provided");
            return;
        }

        try {
            const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/changeClient', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    clientId: currentClientId,
                    clientData
                }),
            });

            const result = await response.json();

            console.log(result)

            if (result.error) {
                setError(result.error);
            } else {
                setIsEditDialogOpen(false);
                setIsDialogOpen(false);
                fetchClients();
            }
        } catch (error) {
            setError("An error occurred while editing the client");
        }
    };

    useEffect(() => { console.log(customers) }, [customers])

    const openEditDialog = (customer) => {
        setClientData({
            name: customer.company.name,
            taxNumber: customer.company.taxNumber,
            vatRegistrationId: customer.company.vatRegistrationId,
            allowTaxFreeInvoices: customer.company.allowTaxFreeInvoices,
            contactPersons: customer.company.contactPersons,
            addresses: customer.addresses,
            emailAddresses: customer.emailAddresses,
            phoneNumbers: customer.phoneNumbers,
            note: customer.note
        });
        setCurrentClientId(customer._id);
        setIsEditDialogOpen(true);
        setCurrentStep(1);
    };

    const renderStep1 = () => (
        <div>
            <div className="mb-4">
                <label className="block mb-2">Kundenname</label>
                <input
                    type="text"
                    name="name"
                    value={clientData.name}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Steuernummer</label>
                <input
                    type="text"
                    name="taxNumber"
                    value={clientData.taxNumber}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">USt-IdNr</label>
                <input
                    type="text"
                    name="vatRegistrationId"
                    value={clientData.vatRegistrationId}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>
            <div className="mb-4">
                <label className="block mb-2">Steuerfreie Rechnungen erlauben</label>
                <input
                    type="checkbox"
                    name="allowTaxFreeInvoices"
                    checked={clientData.allowTaxFreeInvoices}
                    onChange={(e) => setClientData({ ...clientData, allowTaxFreeInvoices: e.target.checked })}
                    className="mr-2"
                />
            </div>
            <div className="flex justify-between">
                <button
                    onClick={() => {
                        setIsDialogOpen(false)
                        setIsEditDialogOpen(false)
                    }}
                    className="text-[#0000FF] py-2 px-4 rounded-md"
                >
                    Abbrechen
                </button>
                <button
                    onClick={handleNextStep}
                    className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                >
                    Weiter
                </button>
            </div>
        </div>
    );

    const renderStep2 = () => (
        <div>
            <div className="mb-4">
                <label className="block mb-2">Kontaktperson</label>
                {clientData.contactPersons.map((person, index) => (
                    <div key={index} className="mb-2">
                        <div className="flex space-x-2">
                            <input
                                type="text"
                                name="salutation"
                                value={person.salutation}
                                placeholder="Anrede"
                                onChange={(e) => handleContactPersonChange(e, index)}
                                className="w-1/4 p-2 border border-gray-300 rounded-md"
                            />
                            <input
                                type="text"
                                name="firstName"
                                value={person.firstName}
                                placeholder="Vorname"
                                onChange={(e) => handleContactPersonChange(e, index)}
                                className="w-1/2 p-2 border border-gray-300 rounded-md"
                            />
                            <input
                                type="text"
                                name="lastName"
                                value={person.lastName}
                                placeholder="Nachname"
                                onChange={(e) => handleContactPersonChange(e, index)}
                                className="w-1/2 p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <input
                            type="email"
                            name="emailAddress"
                            value={person.emailAddress}
                            placeholder="E-Mail-Adresse"
                            onChange={(e) => handleContactPersonChange(e, index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="phoneNumber"
                            value={person.phoneNumber}
                            placeholder="Telefonnummer"
                            onChange={(e) => handleContactPersonChange(e, index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                ))}
            </div>
            <div className="flex justify-between">
                <button
                    onClick={handlePreviousStep}
                    className="text-[#0000FF] py-2 px-4 rounded-md"
                >
                    Zurück
                </button>
                <button
                    onClick={handleNextStep}
                    className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                >
                    Weiter
                </button>
            </div>
        </div>
    );

    const renderStep3 = () => (
        <div>
            <div className="mb-4">
                <label className="block mb-2">Rechnungsadresse</label>
                {clientData.addresses.billing.map((address, index) => (
                    <div key={index} className="mb-2">
                        <input
                            type="text"
                            name="supplement"
                            value={address.supplement}
                            placeholder="Adresszusatz"
                            onChange={(e) => handleAddressChange(e, 'billing', index)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="street"
                            value={address.street}
                            placeholder="Straße"
                            onChange={(e) => handleAddressChange(e, 'billing', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="zip"
                            value={address.zip}
                            placeholder="PLZ"
                            onChange={(e) => handleAddressChange(e, 'billing', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="city"
                            value={address.city}
                            placeholder="Stadt"
                            onChange={(e) => handleAddressChange(e, 'billing', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                ))}
            </div>
            <div className="mb-4">
                <label className="block mb-2">Lieferadresse</label>
                {clientData.addresses.shipping.map((address, index) => (
                    <div key={index} className="mb-2">
                        <input
                            type="text"
                            name="supplement"
                            value={address.supplement}
                            placeholder="Adresszusatz"
                            onChange={(e) => handleAddressChange(e, 'shipping', index)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="street"
                            value={address.street}
                            placeholder="Straße"
                            onChange={(e) => handleAddressChange(e, 'shipping', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="zip"
                            value={address.zip}
                            placeholder="PLZ"
                            onChange={(e) => handleAddressChange(e, 'shipping', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                        <input
                            type="text"
                            name="city"
                            value={address.city}
                            placeholder="Stadt"
                            onChange={(e) => handleAddressChange(e, 'shipping', index)}
                            className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                ))}
            </div>
            <div className="flex justify-between">
                <button
                    onClick={handlePreviousStep}
                    className="text-[#0000FF] py-2 px-4 rounded-md"
                >
                    Zurück
                </button>
                <button
                    onClick={handleNextStep}
                    className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                >
                    Weiter
                </button>
            </div>
        </div>
    );

    const renderStep4 = () => (
        <div className="h-96 overflow-y-auto">
            <div className="mb-4">
                <label className="block mb-2">E-Mail-Adressen</label>
                {Object.keys(clientData.emailAddresses).map((type, typeIndex) => (
                    <div key={type} className="mb-2">
                        <label className="block mb-1">{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                        {clientData.emailAddresses[type].map((email, index) => (
                            <input
                                key={index}
                                type="email"
                                value={email}
                                placeholder={`${type} E-Mail`}
                                onChange={(e) => handleEmailChange(e, type, index)}
                                className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                            />
                        ))}
                    </div>
                ))}
            </div>
            <div className="mb-4">
                <label className="block mb-2">Telefonnummern</label>
                {Object.keys(clientData.phoneNumbers).map((type, typeIndex) => (
                    <div key={type} className="mb-2">
                        <label className="block mb-1">{type.charAt(0).toUpperCase() + type.slice(1)}</label>
                        {clientData.phoneNumbers[type].map((phone, index) => (
                            <input
                                key={index}
                                type="text"
                                value={phone}
                                placeholder={`${type} Telefonnummer`}
                                onChange={(e) => handlePhoneChange(e, type, index)}
                                className="w-full mt-2 p-2 border border-gray-300 rounded-md"
                            />
                        ))}
                    </div>
                ))}
            </div>
            <div className="mb-4">
                <label className="block mb-2">Notiz</label>
                <textarea
                    name="note"
                    value={clientData.note}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                ></textarea>
            </div>
            <div className="flex justify-between">
                <button
                    onClick={handlePreviousStep}
                    className="text-[#0000FF] py-2 px-4 rounded-md"
                >
                    Zurück
                </button>
                <button
                    onClick={handleEditClient}
                    className="bg-[#0000FF] text-white py-2 px-4 rounded-md"
                >
                    Speichern
                </button>
            </div>
        </div>
    );

    return (
        <div className="px-[90px] py-[112px]">
            <div className="flex justify-between items-center mb-[72px]">
                <h1 className="text-[60px] leading-[63px] font-bold">Aktuelle Kunden</h1>
                <button
                    className="bg-[#0000FF] text-white px-[20px] py-[12px] rounded-lg"
                    onClick={() => { setIsDialogOpen(true); setCurrentStep(1); }}
                >
                    Hinzufügen
                </button>
            </div>
            <div className="overflow-x-auto rounded-3xl">
                <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                    <thead>
                        <tr>
                            <th className="pt-[64px] py-3 px-8 border-b text-left text-[#B2B2B2]">Kundenname</th>
                            <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Ansprechpartner</th>
                            <th className="pt-[64px] py-3 px-4 border-b text-left text-[#B2B2B2]">Hinzugefügt am</th>
                            <th className="pt-[64px] py-3 px-8 border-b text-left text-[#B2B2B2]"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.map((customer) => (
                            <tr key={customer._id}>
                                <td className="pb-[64px] py-3 px-8 border-b font-[700]">{customer.company.name}</td>
                                <td className="pb-[64px] py-3 px-4 border-b">{customer.company.contactPersons?.[0]?.firstName} {customer.company.contactPersons?.[0]?.lastName}</td>
                                <td className="pb-[64px] py-3 px-4 border-b">{new Date(parseInt(customer._id.substring(0, 8), 16) * 1000).toLocaleDateString()}</td>
                                <td className="pb-[64px] py-3 px-8 border-b text-[#0000FF] text-right font-[700]">
                                    <button onClick={() => openEditDialog(customer)}>Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {isDialogOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg overflow-y-auto">
                        <h2 className="text-xl font-bold mb-4">Neuen Kunden hinzufügen</h2>
                        {error && <p className="text-red-600 mb-4">{error}</p>}
                        <div className="flex justify-between mb-4">
                            <span className={`px-3 py-1 rounded-full ${currentStep === 1 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>1</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 2 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>2</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 3 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>3</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 4 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>4</span>
                        </div>
                        {currentStep === 1 && renderStep1()}
                        {currentStep === 2 && renderStep2()}
                        {currentStep === 3 && renderStep3()}
                        {currentStep === 4 && renderStep4()}
                    </div>
                </div>
            )}

            {isEditDialogOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-md w-full max-w-lg overflow-y-auto">
                        <h2 className="text-xl font-bold mb-4">Kunden bearbeiten</h2>
                        {error && <p className="text-red-600 mb-4">{error}</p>}
                        <div className="flex justify-between mb-4">
                            <span className={`px-3 py-1 rounded-full ${currentStep === 1 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>1</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 2 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>2</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 3 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>3</span>
                            <span className={`px-3 py-1 rounded-full ${currentStep === 4 ? 'bg-[#0000FF] text-white' : 'bg-gray-200'}`}>4</span>
                        </div>
                        {currentStep === 1 && renderStep1()}
                        {currentStep === 2 && renderStep2()}
                        {currentStep === 3 && renderStep3()}
                        {currentStep === 4 && renderStep4()}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Clients;
