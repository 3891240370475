import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Realm from 'realm-web';

const app = new Realm.App({ id: "data-ywwpoom" });

const PasswordResetForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [tokenId, setTokenId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.clear();

    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const tokenId = query.get('tokenId');
    if (token && tokenId) {
      setToken(token);
      setTokenId(tokenId);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      await app.emailPasswordAuth.resetPassword({ token, tokenId, password });
      setMessage("Password reset successfully. You can now log in with your new password.");
      navigate('/login');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="max-w-lg w-full space-y-8 p-10">
        <h1 className="text-2xl font-bold text-center text-[#424B58]">Reset Password</h1>
        {token && tokenId ? (
          <form onSubmit={handleSubmit} className="mt-6 space-y-6">
            <div className="rounded-md">
              <div>
                <label
                  htmlFor="new-password"
                  className="text-[#424B58] text-sm font-semibold"
                >
                  New Password
                </label>
                <input
                  id="new-password"
                  name="new-password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder="Enter new password"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="confirm-password"
                  className="text-[#424B58] text-sm font-semibold"
                >
                  Confirm New Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="appearance-none bg-[#FCFCFC] relative block w-full px-4 py-3 border-2 border-[#EFEFF0] placeholder-gray-400 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm"
                  placeholder="Confirm new password"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primaryDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            </div>
          </form>
        ) : (
          <p className="text-sm text-red-600 mt-4 text-center">Invalid or missing token.</p>
        )}
        {message && <p className="text-sm text-center mt-4">{message}</p>}
      </div>
    </div>
  );
};

export default PasswordResetForm;
