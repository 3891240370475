import React, { useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const QRCodeDialog = ({ onClose, token, firstName, lastName, employeeId }) => {
  const qrRef = useRef(null);

  const downloadQR = () => {
    const qrCanvas = qrRef.current.querySelector('canvas');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const qrSize = 256;
    const textHeight = 30;
    const padding = 20;
    const bottomPadding = 60;
    const totalHeight = qrSize + textHeight * 2 + padding + bottomPadding;

    canvas.width = qrSize + padding * 2;
    canvas.height = totalHeight;

    context.fillStyle = "#FFFFFF";
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.drawImage(qrCanvas, padding, padding, qrSize, qrSize);

    context.fillStyle = "#000000";
    context.font = "20px Arial";
    context.textAlign = "center";
    context.fillText(`${firstName} ${lastName}`, canvas.width / 2, qrSize + padding + bottomPadding - textHeight);
    context.fillText(`ID: ${employeeId}`, canvas.width / 2, qrSize + padding + bottomPadding);

    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qr-code.png';
    link.click();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-center mb-4">QR-Code</h2>
        <div className="flex justify-center mb-4" ref={qrRef}>
          <QRCodeCanvas value={token} size={256} />
        </div>
        <div className="text-center mb-4">
          <p>{firstName.charAt(0).toUpperCase()}. {lastName}</p>
          <p>ID: {employeeId}</p>
        </div>
        <div className="flex">
          <button onClick={onClose} className="text-[#0000FF] py-2 px-4 rounded-md mr-[5px]">Schließen</button>
          <button onClick={downloadQR} className="bg-[#0000FF] text-white py-2 px-4 rounded-md">Herunterladen</button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeDialog;