import React, { useState } from 'react';

const EmployeeSelectionDialog = ({ onClose, onSave, allEmployees }) => {
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const availableEmployees = allEmployees

  const handleSave = () => {
    const selected = availableEmployees.find(emp => emp._id === selectedEmployee);
    onSave(selected);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h2 className="text-2xl font-bold mb-4">Mitarbeiter auswählen</h2>
        <div className="mb-4 flex items-center">
          <select
            className="w-full border rounded px-3 py-2"
            onChange={(e) => setSelectedEmployee(e.target.value)}
            value={selectedEmployee}
          >
            <option value="">Mitarbeiter auswählen</option>
            {availableEmployees.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-[#0000FF] text-white py-2 px-4 rounded mr-2"
            onClick={handleSave}
          >
            Speichern
          </button>
          <button
            type="button"
            className="text-[#0000FF] py-2 px-4 rounded"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSelectionDialog;