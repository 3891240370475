import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

const FileUploadDialog = ({ onClose }) => {
  const [uploadStatus, setUploadStatus] = useState(null);
  const token = localStorage.getItem('accessToken');

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['.csv', '.xlsx'],
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

        let employeeSchedules = [];

        worksheet.slice(5).forEach((row) => {
          if (row[0] && row[2]) {
            const employeeId = row[2];
            const employeeName = row[0];
            const schedule = [];

            const daysOfWeek = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
            for (let i = 0; i < daysOfWeek.length; i++) {
              const startTime = XLSX.SSF.format('HH:MM', row[5 + i * 4]);
              const endTime = XLSX.SSF.format('HH:MM', row[6 + i * 4]);
              const breakTime = row[7 + i * 4];
              const location = row[8 + i * 4];

              if (startTime && endTime) {
                schedule.push({
                  Datum: daysOfWeek[i],
                  Start: startTime,
                  Ende: endTime,
                  'Pausen (min)': breakTime,
                  Standort: location,
                });
              }
            }

            const employeeSchedule = {
              employeeId: employeeId,
              name: employeeName,
              schedule: schedule,
              company: '',
            };

            employeeSchedules.push(employeeSchedule);
          }
        });

        fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addRoster', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token,
            employeeSchedules
          }),
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setUploadStatus('success');
          } else {
            setUploadStatus('error');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setUploadStatus('error');
        });
      };
      reader.readAsArrayBuffer(file);
    },
  });

  const handleClose = () => {
    setUploadStatus(null);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-10 rounded-lg shadow-lg w-3/4 max-w-3xl h-auto">
        <h2 className="text-2xl font-bold text-center mb-8">Dateiupload</h2>
        <div {...getRootProps()} className="border-dashed border-2 border-gray-300 p-10 text-center cursor-pointer mb-8">
          <input {...getInputProps()} />
          <p className="text-lg text-black">Klicken oder Datei per Drag & Drop einfügen</p>
          <p className="text-sm text-gray-500">Dateien im Format csv und .xlsx zum Hochladen auswählen</p>
        </div>
        <div className="flex justify-center">
          <button onClick={handleClose} className="bg-gray-500 text-white py-2 px-6 rounded-md hover:bg-gray-600">Abbrechen</button>
        </div>
      </div>

      {uploadStatus && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 rounded-lg shadow-lg w-3/4 max-w-3xl h-auto text-center">
            {uploadStatus === 'success' ? (
              <>
                <img src="/login-success.png" alt="Success" className="mx-auto mb-4 w-20 h-20" />
                <h2 className="text-2xl font-bold text-black mb-4">Upload erfolgreich!</h2>
              </>
            ) : (
              <h2 className="text-2xl font-bold text-black mb-4">Upload fehlgeschlagen</h2>
            )}
            <button onClick={handleClose} className="bg-[#0000FF] text-white py-2 px-6 rounded-md hover:bg-blue-700">Schließen</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadDialog;