import React, { useState } from 'react';
import * as Realm from "realm-web";

const EmployeeAccessForm = ({ onCancel, employeeInfo }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [sendInvitation, setSendInvitation] = useState(false);
  const [manualAccess, setManualAccess] = useState(true);
  const [message, setMessage] = useState('');

  const token = localStorage.getItem('accessToken');
  const companyToken = localStorage.getItem('company');

  const handleCheckboxChange = (e) => {
    setSendInvitation(e.target.checked);
  };

  const handleManualAccessChange = (e) => {
    setManualAccess(e.target.checked);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    const processedEmail = email.includes('@') ? email.toLowerCase() : `${email.toLowerCase()}@time-snap.de`;

    if (!manualAccess || !email || !password) {
      setMessage("Manual access must be checked, and email and password must be filled out.");
      return;
    }

    try {
      let requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': '*',
        },
        body: JSON.stringify({
          email: processedEmail,
          password: password,
          firstName: employeeInfo.firstName,
          lastName: employeeInfo.lastName,
          jobTitle: employeeInfo.jobTitle,
          employeeId: employeeInfo.employeeId,
          timeModel: employeeInfo.workTimeModel,
          hours_week: employeeInfo.weeklyWorkHours,
          hours_month: employeeInfo.monthlyWorkHours,
          isAdmin: employeeInfo.adminChecked,
          isSuperAdmin: employeeInfo.superAdminChecked,
          holidayValue: employeeInfo.holidayValue,
          companyId: companyToken,
          token
        }),
        redirect: 'follow'
      };

      fetch(process.env.REACT_APP_REGISTER_URL, requestOptions)
        .then(response => response.json())
        .then(async (result) => {
          if (!result.error) {
            const app = new Realm.App({ id: process.env.REACT_APP_MONGO_APP });
            await app.emailPasswordAuth.registerUser({ email: processedEmail, password });
            console.log(result);
            setMessage("Mitarbeiter erfolgreich registriert");
  
            const loginResponse = await fetch("https://services.cloud.mongodb.com/api/client/v2.0/app/data-ywwpoom/auth/providers/local-userpass/login", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ username: processedEmail, password: password }),
            });
  
            const loginData = await loginResponse.json();
            const refreshToken = loginData.refresh_token;
  
            const saveTokenResponse = await fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/saveToken", {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                employeeId: employeeInfo.employeeId,
                token: token,
                refreshToken: refreshToken,
              }),
            });
  
            const saveTokenData = await saveTokenResponse.json();
            console.log(saveTokenData);
          }
          if (result.error) setMessage(`Error creating user: ${result.error}`)
        })
        .catch(error => {
          console.log('error', error);
          setMessage("Error registering user");
        });

    } catch (error) {
      console.error("Error registering user:", error);
      setMessage("Error registering user");
    }
  };

  return (
    <div className="bg-gray-100 w-full max-w-screen-xl mx-auto">
      <header className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-800">Neuen Mitarbeiter anlegen</h1>
          <p className="text-sm text-gray-600">Alle Mitarbeiter &gt; Neuen Mitarbeiter anlegen</p>
        </div>
      </header>

      <h2 className="text-xl font-bold text-gray-800 mb-4">Zugangsdaten</h2>
      
      <form onSubmit={handleRegister}>
        <div className="mb-6">
          <div className="flex items-center mb-2">
            <input 
              type="checkbox" 
              className="mr-2" 
              checked={sendInvitation} 
              onChange={handleCheckboxChange} 
              disabled
            />
            <label className="text-gray-700">Einladungslink versenden</label>
          </div>
          <div className="flex items-center mb-2">
            <input 
              type="checkbox" 
              className="mr-2" 
              checked={manualAccess} 
              onChange={handleManualAccessChange} 
            />
            <label className="text-gray-700">Zugangsdaten manuell festlegen</label>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 mb-6">
          <div>
            <label className="block text-gray-700 mb-1">E-Mail oder Benutzerdaten</label>
            <input 
              className="w-full p-2 border border-gray-300 rounded-md" 
              placeholder="z.B. ichliebegrünkohl@web.de" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-1">Initialpasswort</label>
            <input 
              type="password" 
              className="w-full p-2 border border-gray-300 rounded-md" 
              placeholder="z.B. passwort123" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required
            />
          </div>
        </div>

        <div className="flex space-x-4">
          <button type="submit" className="bg-[#0000FF] text-white py-2 px-4 rounded-md ">Weiter</button>
          <button 
            type="button"
            onClick={onCancel} 
            className="text-[#0000FF] py-2 px-4 rounded-md "
          >
            Abbrechen
          </button>
        </div>
        {message && <p className="mt-4">{message}</p>}
      </form>
    </div>
  );
};

export default EmployeeAccessForm;