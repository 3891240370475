import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';

function Employee({ setLoggedIn }) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState({});
    const [fileData, setFileData] = useState(null);
    const [holidayData, setHolidayData] = useState(null);
    const [inputError, setInputError] = useState(null);
    const token = localStorage.getItem('accessToken');

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            jsonData.shift();
            setFileData(jsonData);
        };
        reader.readAsArrayBuffer(file);
    };

    const onHolidayDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            let workbook = null;
            try {
                workbook = XLSX.read(event.target.result, {
                    cellStyles: true,
                });
                console.log("Workbook: ", workbook);
            } catch (error) {
                setHolidayData(null);
                setInputError("Unsupported file format");
                return;
            }

            const sheet_name = workbook.SheetNames;
            const sheet = workbook.Sheets[sheet_name[0]];
            const cells = Object.entries(sheet).filter(([cell]) => {
                if (cell.startsWith("!")) {
                    return false;
                }

                const rowNum = cell.replace(/[A-Z]+/, "");
                return rowNum > 4;
            });

            const rows = {};

            for (const cell of cells) {
                const cellId = cell[0];
                const rowNum = cellId.replace(/[A-Z]+/, "");

                if (!rows[rowNum]) {
                    rows[rowNum] = [];
                }

                let status = null;

                if (cell[1].s.patternType === "lightGrid") {
                    status = "Freizeitausgleich";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "DBDBDB"
                ) {
                    status = "Schulung";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "FF0000"
                ) {
                    status = "Feiertage";
                } else if (
                    (cell[1].s.fgColor && cell[1].s.fgColor.rgb === "FFFF00") ||
                    (cell[1].s.fgColor && cell[1].s.fgColor.rgb === "FFC000")
                ) {
                    status = "Ferien";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "9DC3E6"
                ) {
                    status = "Planung";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "92D050"
                ) {
                    status = "Urlaub";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "AFABAB"
                ) {
                    status = "Elternzeit";
                } else if (
                    (cell[1].s.fgColor &&
                        cell[1].s.fgColor.tint === -0.249977111117893) ||
                    (cell[1].s.fgColor && cell[1].s.fgColor.rgb === "BFBFBF")
                ) {
                    status = "Wochenende";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "7030A0"
                ) {
                    status = "Bildungsurlaub";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "F4B183"
                ) {
                    status = "Fortbildung";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "C5E0B4"
                ) {
                    status = "Prüfung";
                } else if (
                    cell[1].s.fgColor &&
                    cell[1].s.fgColor.rgb === "FFD966"
                ) {
                    status = "load";
                } else if (cell[1].v === "K") {
                    status = "Kur";
                } else if (cell[1].v === "k") {
                    status = "krank";
                } else if (cell[1].v === "R") {
                    status = "Reinigung";
                } else if (cell[1].v === "X") {
                    status = "Kindkrank";
                }

                rows[rowNum].push({
                    cellId: cell[0],
                    status: status,
                    value: cell[1].v,
                });
            }

            const employees = getEmployees(rows);
            setInputError(null);
            setHolidayData(employees);
            
            console.log(employees)

            fetch("https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/addHolidayPlan", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    holidayPlans: employees,
                }),
            })
                .then(response => response.json())
                .then(result => {
                    console.log('Success:', result);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        reader.readAsArrayBuffer(file);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { getRootProps: getHolidayRootProps, getInputProps: getHolidayInputProps } = useDropzone({ onDrop: onHolidayDrop });

    useEffect(() => {
        localStorage.setItem('company', employee?.company)
    }, [employee]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');

        if (!token) {
            navigate('/login');
            return;
        }

        validateToken(token)
            .then(isValid => {
                if (!isValid) {
                    setLoggedIn(false)
                    navigate('/login');
                }
            })
            .catch(error => {
                console.error('Error validating token:', error);
                setLoggedIn(false)
                navigate('/login');
            });
    }, [token, setLoggedIn, navigate]);

    const validateToken = (token) => {
        return new Promise((resolve, reject) => {
            try {
                let requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': '*',
                    },
                    body: JSON.stringify({
                        token: token,
                    }),
                    redirect: 'follow'
                };

                fetch(process.env.REACT_APP_EMPLOYEE_URL, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.error) {
                            resolve(false);
                        }

                        if (!result.error) {
                            console.log(result)
                            setEmployee(result);
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        reject(error);
                    });
            } catch (error) {
                console.error('Error:', error);
                reject(error);
            }
        });
    };

    useEffect(() => {
        if (fileData) {
            addAnnualPlans();
        }
    }, [fileData]);

    const addAnnualPlans = async () => {
        const url = process.env.REACT_APP_ANNUALPLAN_URL;
        const data = fileData?.map((row, i) => {
            if (i === 0) {
                return null;
            }

            return {
                employeeId: row[0],
                name: row[1],
                monday: row[2],
                tuesday: row[3],
                wednesday: row[4],
                thursday: row[5],
                friday: row[6],
            };
        }).filter(plan => plan !== null);

        console.log({
            annualPlans: data,
            token: token
        });

        if (data) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    annualPlans: data,
                    token: token
                })
            })
                .then(response => response.json())
                .then(result => {
                    console.log('Add Annual Plans result:', result);
                })
                .catch(error => {
                    console.error('Error adding annual plans:', error);
                });
        }
    };

    return (
        <div>
            <div {...getRootProps()} style={{ cursor: 'pointer', border: '2px dashed #ccc', padding: '20px', marginBottom: '20px' }}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop an Excel file here for annual plans, or click to select one</p>
            </div>
            {fileData && (
                <div>
                    <h2>File Data</h2>
                    <table>
                        <tbody>
                            {fileData.map((row, index) => (
                                <tr key={index}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div {...getHolidayRootProps()} style={{ cursor: 'pointer', border: '2px dashed #ccc', padding: '20px', marginBottom: '20px' }}>
                <input {...getHolidayInputProps()} />
                <p>Drag 'n' drop an Excel file here for holiday plans, or click to select one</p>
            </div>
            {holidayData && (
                <div>
                    <h2>Holiday Data</h2>
                    <pre>{JSON.stringify(holidayData, null, 2)}</pre>
                </div>
            )}
            <div>
                <h2 onClick={() => console.log(employee)}>Employee Data</h2>
            </div>
            {inputError && <div style={{ color: 'red' }}>{inputError}</div>}
        </div>
    );
}

const getEmployees = (rows) => {
    let date = new Date(`${new Date().getFullYear()}-01-01`);
    let res = {};

    for (const [, value] of Object.entries(rows)) {
        if (value[2].value !== undefined) {
            res[value[2].value] = value.slice(6)
                .map((obj) => {
                    let newDate = new Date(date);
                    date.setDate(date.getDate() + 1);
                    return { ...obj, date: newDate };
                })
                .filter(timeslot => timeslot.status !== null);
        }
    }

    return res;
};

export default Employee;