import React, { useState } from 'react';

const RejectionDialog = ({ isOpen, onClose, onSave }) => {
  const [reason, setReason] = useState('');

  const handleSave = () => {
    onSave(reason);
    setReason('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-[300px] p-4">
        <h1 className="text-left text-[#0000FF] border-b font-bold text-[18px] p-2 pl-4">Grund für Ablehnung</h1>
        <textarea
          className="w-full p-2 border border-gray-300 rounded mb-4"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div className="flex justify-end">
          <button className=" text-[#0000FF] py-2 px-4 rounded-lg mr-2" onClick={onClose}>Abbrechen</button>
          <button className="bg-[#0000FF] text-white py-2 px-4 rounded-lg" onClick={handleSave}>Speichern</button>
        </div>
      </div>
    </div>
  );
};

export default RejectionDialog;
