import React, { useState, useEffect } from 'react';

const ProjectSelectionDialog = ({ onClose, onSave, token, existingProjects }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(
    existingProjects ? { id: Object.keys(existingProjects)[0], name: Object.values(existingProjects)[0] } : { id: '', name: '' }
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch('https://eu-central-1.aws.data.mongodb-api.com/app/data-ywwpoom/endpoint/getProjects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const result = await response.json();
        if (result.error) {
          console.error('Error fetching projects:', result.error);
        } else {
          setProjects(result);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchProjects();
  }, [token]);

  const handleProjectChange = (projectId) => {
    const project = projects.find((proj) => proj._id === projectId);
    setSelectedProject({ id: projectId, name: project.name });
  };

  const handleSave = () => {
    const projectMap = selectedProject.id ? { [selectedProject.id]: selectedProject.name } : {};

    console.log(projectMap)
    onSave(projectMap);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h2 className="text-2xl font-bold mb-4">Projekt auswählen</h2>
        <div className="mb-4 flex items-center">
          <select
            className="w-full border rounded px-3 py-2"
            onChange={(e) => handleProjectChange(e.target.value)}
            value={selectedProject.id}
          >
            <option value="">Projekt auswählen</option>
            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-[#0000FF] text-white py-2 px-4 rounded mr-2"
            onClick={handleSave}
          >
            Speichern
          </button>
          <button
            type="button"
            className="text-[#0000FF] py-2 px-4 rounded"
            onClick={onClose}
          >
            Abbrechen
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectSelectionDialog;
